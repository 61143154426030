/*
TIMEPROGRESS
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Jul 15, 2024
UPDATE DATE: Jul 15, 2024
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: TimeProgress | Know you time progress!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 70px;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);
  text-align: center;
  box-sizing: border-box;

  > a{
    display: inline-block;
    font-size: 20px;
    line-height: 40px;
    font-weight: bold;
    margin: 0;

    > svg{
      display: block;
      // width: 164px;
      width: 200px;
      height: 40px;
    }
  }
}

.pad-top{
  height: 70px;
}

ul.the-progress{
  padding: 10px;

  > li{
    padding: 10px;

    > div{
      background: #EEE;
      padding: 15px 20px 20px 20px;

      > ul{
        > li{
          display: inline-block;
          font-size: 16px;
          line-height: 20px;

          &:nth-child(1){
            color: #666;
            width: 30%;
          }

          &:nth-child(2){
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            width: 40%;

            > strong{
              // display: block;
              font-size: 16px;
              line-height: 20px;
            }
          }

          &:nth-child(3){
            text-align: right;
            font-size: 12px;
            line-height: 16px;
            width: 30%;

            > strong{
              color: #666;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }

      > div{
        position: relative;
        margin: 10px 0 0 0;
        background: #AAA;

        > span{
          position: absolute;
          color: #FFF;
          right: 10px;
          left: 10px;
          text-align: center;
          top: 50%;
          transform: translate(0, -50%);
          font-weight: bold;
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          z-index: 1;
        }

        > div{
          position: relative;
          background: #666;
          height: 40px;
          box-sizing: border-box;
          // overflow: hidden;
          z-index: 0;

          // &:after{
          //   content: '';
          //   position: absolute;
          //   background: #666;
          //   width: 30px;
          //   height: 30px;
          //   right: 0;
          //   top: 0;
          //   box-shadow: 0 0 10px #FFF;
          // }
        }
      }
    }
  }
}

footer{
  text-align: center;
  padding: 20px 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;
  background: rgba(255, 255, 255, .9);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){
  ul.the-progress{
    padding: 12.5px;
  
    > li{
      padding: 7.5px;
      
      > div{
        padding: 10px 15px 15px 15px;

        > ul{
          > li{
            &:nth-child(2){
              font-size: 11px;
              line-height: 15px;
  
              > strong{
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }

        > div{
          > div{
            height: 35px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){
  footer{
    padding: 0 20px 20px 20px;
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/